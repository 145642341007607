<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt--6">
      <table-navigation :table-title="tableTitle"
                        :table-buttons="tableButtons"
                        :table-columns="tableColumns"
                        :table-data="tableData"
                        :router-view="routerView"
                        :connection-error="connectionError"
                        :pagination="pagination"
                        :filter-options="filterOptions"
                        :filters="tableFilters"
                        @page="changePage"
                        @filters="changeFilters"
      >
      </table-navigation>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
import TableNavigation from "@/views/Store/Tables/TableNavigation";
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";

const {mapState, mapActions} = createNamespacedHelpers('CustomerListModule')

export default {
  name: 'CustomerList',
  components: {
    BreadcrumbHeader,
    TableNavigation,
  },
  data() {
    return {
      tableTitle: 'Lista klientów',
      routerView: 'customer-view',
      tableButtons: [],
      tableColumns: [
        {
          prop: 'avatar',
          label: '',
          minWidth: 30,
          sortable: false,
        },
        {
          prop: 'firstName',
          label: 'Imię',
          minWidth: 80,
          sortable: true,
          link: true,
        },
        {
          prop: 'lastName',
          label: 'Nazwisko',
          minWidth: 100,
          sortable: true,
          link: true,
        },
        {
          prop: 'email',
          label: 'Email',
          minWidth: 120,
          sortable: true,
          link: true,
        },
        {
          prop: 'phone',
          label: 'Telefon',
          minWidth: 80,
          sortable: true,
        },
        {
          prop: 'active',
          label: 'Aktywny',
          minWidth: 60,
          sortable: false,
        },
      ],
      filterOptions: [
        {field: 'firstName', label: 'Imię'},
        {field: 'lastName', label: 'Nazwisko'},
        {field: 'email', label: 'Email'},
        {field: 'active', label: 'Aktywny'},
      ],
    }
  },
  computed: {
    ...mapState({
      tableData: state => state.items,
      totalItems: state => state.totalItems,
      tableFilters: state => state.filterValues,
      connectionError: state => state.apiConnectionErr
    }),
    breadcrumb() {
      return [
        {title: 'Klienci', link: {name: 'clients'}},
        {title: 'Detaliczni'},
      ];
    },
    handleError() {
      return `Błąd ${this.connectionError.status} - ${this.$t[this.connectionError.status]}`;
    },
    pagination() {
      return {
        totalItems: this.totalItems,
        perPage: 20,
        currentPage: parseInt(this.$route.params.pageNum || 1),
      };
    },
  },
  methods: {
    changePage(page) {
      this.$router.push({name: 'customer-list', params: {pageNum: page}});
    },
    changeFilters(filters) {
      this.$store.commit('CustomerListModule/setFilterValues', filters);
      this.$store.dispatch('CustomerListModule/fetchList', {
        token: this.$store.state.Auth.token,
        page: 1,
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('CustomerListModule/resetItems', []);
    return next()
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit('CustomerListModule/resetItems', []);
    this.$store.dispatch('CustomerListModule/fetchList', {
      token: this.$store.state.Auth.token,
      page: to.params.pageNum || 1,
    });
    return next();
  },
  beforeCreate() {
    this.$store.dispatch('CustomerListModule/fetchList', {
      token: this.$store.state.Auth.token,
      page: this.$route.params.pageNum || 1,
    });
  }
};
</script>
